import { window } from 'browser-monads'

export const isMobile = () => {
    if (window.matchMedia('(orientation: landscape)').matches) {
      return window.innerWidth <= 823
    } else if (window.matchMedia('(orientation: portrait)').matches) {
      return window.innerWidth <= 767
    }
  
    return true
}
