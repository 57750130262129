import classNames from 'classnames';
import Img from 'gatsby-image';
import React, { Component } from 'react';
import eventEmitter from '../../../eventEmitter';

import CollapseArrow from '../../CollapseArrow';

import styles from './styles.module.css';

class TrayItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pictureIndex: 0,
			dynamicSubsectionIndex: 0,
			color: undefined,
			imageShouldHide: false,
		};
	}

	componentDidMount() {
		this.setState({ pictureIndex: 0, dynamicSubsectionIndex: 0 });
		eventEmitter.on('displayResearchPicture', index => {
			this.setState({
				imageShouldHide: true,
			});
			if (this.props.id === 1 && index !== false) {
				this.setState({
					color: true,
					pictureIndex: index,
					dynamicSubsectionIndex: index,
				});
			} else {
				this.setState({
					color: undefined,
					pictureIndex: 0,
					dynamicSubsectionIndex: 0,
				});
			}
			setTimeout(() => {
				this.setState({
					imageShouldHide: false,
				});
			}, 5);
		});

		// this.setState({ pictureIndex: 0 });
		// eventEmitter.on('displayResearchPicture', index => {
		// 	this.setState({
		// 		imageShouldHide: true,
		// 	});
		// 	if (this.props.id === 1 && index !== false) {
		// 		this.setState({ color: true, pictureIndex: index });
		// 	} else {
		// 		this.setState({ color: undefined, pictureIndex: 0 });
		// 	}
		// 	setTimeout(() => {
		// 		this.setState({
		// 			imageShouldHide: false,
		// 		});
		// 	}, 5);
		// });
	}

	render() {
		const isHovered =
			this.props.hovered[this.props.id] &&
			this.props.hovered[this.props.id] !== 'upOne' &&
			this.props.hovered[this.props.id] !== 'upTwo';
		const defaultPicture = this.props.image;
		const selectedPicture = this.props.pictures[this.state.pictureIndex]
			.value.picture.value;
		const selectedSubsectionTitle = this.props.dynamicSubsectionTitles
			? this.props.dynamicSubsectionTitles[
					this.state.dynamicSubsectionIndex
			  ].value.title.value.childMarkdownRemark.htmlAst.children[0]
					.children[0].value
			: null;
		const picture =
			this.state.pictureIndex !== 0 ? selectedPicture : defaultPicture;
		let color = undefined;
		let background = undefined;

		if (!this.state.color) {
			switch (this.props.color) {
				case 'purple':
					color = styles.purple;
					background = styles.purpleBackground;
					break;
				case 'blue':
					color = styles.blue;
					background = styles.blueBackground;
					break;
				case 'green':
					color = styles.green;
					background = styles.greenBackground;
					break;
				case 'yellow':
					color = styles.yellow;
					background = styles.yellowBackground;
					break;
				case 'orange':
					color = styles.orange;
					background = styles.orangeBackground;
					break;
				default:
			}
		} else {
			color = styles.lightOrange;
			background = styles.lightOrangeBackground;
		}

		return (
			<div
				onClick={() =>
					this.props.handleTrays(this.props.id, this.props.linkedPage)
				}
				onMouseOver={() => this.props.handleHover(this.props.id)}
				className={classNames({
					[styles.trayItem]: true,
					[color]: true,
					[styles.expandedBig]:
						this.props.expanded &&
						(this.props.id === 1 || this.props.id === 5) &&
						!(
							this.props.expanded &&
							this.props.hovered[this.props.id] === 'upOne'
						),
					[styles.expandedSmall]:
						this.props.expanded ||
						(this.props.expanded &&
							this.props.hovered[this.props.id] === 'upOne'),
					[styles.expandedHover]:
						this.props.expanded &&
						this.props.hovered[this.props.id] === 'upOne',
					[styles.small]: this.props.small,
					[styles.offscreen]:
						this.props.offscreen ||
						(this.props.hovered[this.props.id] === 'upTwo' &&
							this.props.small &&
							!this.props.offscreen),
					[styles.smallhover]: isHovered && this.props.small,
				})}
			>
				<div
					className={classNames({
						[styles.image]: true,
						[styles.hidden]: !(isHovered || this.props.expanded),
						[styles.imageExpanded]: this.props.expanded,
					})}
				>
					{picture.childImageSharp ? (
						<Img
							className={classNames({
								[styles.imageWrapper]: true,
								[styles.hidden]: this.state.imageShouldHide,
							})}
							fluid={picture.childImageSharp.fluid}
							onStartLoad={this.props.loading.register}
							onLoad={this.props.loading.notify}
						/>
					) : (
						<video
							autoPlay
							loop
							muted
							className={classNames({
								[styles.imageWrapper]: true,
								[styles.hidden]: this.state.imageShouldHide,
							})}
						>
							<source src={picture.publicURL} type="video/mp4" />
						</video>
					)}
					<div className={styles.dynamicSubsectionTitleText}>
						{selectedSubsectionTitle}
					</div>
				</div>
				<div
					className={classNames({
						[styles.body]: true,
						[background]: true,
						[styles.hidden]: !this.props.expanded,
					})}
					dangerouslySetInnerHTML={{
						__html: this.props.body.childMarkdownRemark.html,
					}}
				/>
				<div
					className={classNames({
						[styles.button]: true,
					})}
				>
					<div
						className={classNames({
							[styles.buttonDiv]: true,
							[styles.expandedButton]: this.props.expanded,
						})}
					>
						{!this.props.small ? (
							<CollapseArrow isCollapsed={this.props.expanded} />
						) : (
							<div className={styles.emptyCollapseArrow} />
						)}
					</div>
				</div>
				<div
					className={classNames({
						[styles.title]: true,
						[styles.hidden]:
							this.props.small &&
							!(
								this.props.hovered[this.props.id] &&
								this.props.hovered[this.props.id] !== 'upTwo' &&
								this.props.hovered[this.props.id] !== 'upOne'
							),
						[styles.expandedTitle]: this.props.expanded,
					})}
				>
					<div>
						<div className={styles.titleText}>
							{this.props.title}
						</div>
						<div
							className={classNames({
								[styles.subtitleText]: true,
								[styles.hiddenSubtitleText]: !this.props
									.expanded,
							})}
						>
							{this.props.subtitle}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TrayItem;
