import React, { Component } from 'react'

import styles from './styles.module.css'

class Arrow extends Component {
    render() {
        return (
            <div className={styles.button}>
                <div className={styles.icon}>
                    <div className={styles.right}/>
                </div>
            </div>
        )
    }
}

export default Arrow;