import React, { Component } from 'react'

const withLoading = (WrappedComponent) =>
  class extends Component {
    registrationCount = 0

    constructor(props) {
      super(props)

      this.state = {
        loading: false,
        transitionEnded: false,
      }
    }

    componentDidMount = () => {
      // let element = document.getElementById('loading-screen')
      // element.addEventListener(
      //   'transitionend',
      //   () => {
      //     this.setState({ transitionEnded: true })
      //   },
      //   false
      // )
    }

    register = () => {
      this.registrationCount += 1
      this.setState({
        loading: true,
      })
    }

    notify = () => {
      this.registrationCount -= 1

      if (this.state.loading && this.registrationCount <= 0) {
        this.setState({
          loading: false,
        })
      }
    }

    render() {
      return (
        <>
          {/* <div
            id="loading-screen"
            className={classNames(styles.loading, {
              [styles.loaded]: !this.state.loading,
              [styles.ended]: this.state.transitionEnded,
            })}
          >
            <Loading/>
          </div> */}
          <WrappedComponent
            loading={{
              register: this.register,
              notify: this.notify,
            }}
            {...this.props}
          />
        </>
      )
    }
  }

export default withLoading
