import Arrow from './Arrow'
import classNames from 'classnames'
import { Link } from 'gatsby';
import React, { Component } from 'react'


import styles from './styles.module.css'

class MobileTrayItem extends Component {
  render() {    
    let color = undefined
    switch (this.props.color) {
      case "purple":
        color = styles.purple
        break
      case "blue":
        color = styles.blue
        break
      case "green":
        color = styles.green
        break
      case "yellow":
        color = styles.yellow
        break
      case "orange":
        color = styles.orange
        break
      default:
    }

    return (
      <Link  to={this.props.linkedPage}>
        <div className={classNames(styles.trayItem, color)}>
            <div className={styles.mobileTrayContainer}>
            </div>
            <div className={styles.mobileTrayItem}>
                <div
                  className={styles.title}
                >
                  <div
                    className={styles.titleText}
                  >
                    {this.props.title}
                  </div>
                  <div
                    className={styles.subtitleText}
                  >
                    {this.props.subtitle}
                  </div>
                </div>
                <div className={styles.arrow}>
                  <Arrow />
                </div>
            </div>
        </div>
      </Link>
    )
  }
}

export default MobileTrayItem
