import { StaticQuery, graphql } from 'gatsby';
import BoostedWord from '../BoostedWord';
import classNames from 'classnames';
import React, { Component } from 'react';
import RehypeReact from 'rehype-react';
import WhereTable from './WhereTable';

import { scrollContainer } from '../Accordion';
import styles from './styles.module.css';

class WhereSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
		};
	}

	handleChange = panel => (event, isExpanded) => {
		scrollContainer(event.currentTarget, panel);

		this.setState({
			expanded: isExpanded ? panel : false,
		});
	};

	render() {
		return (
			<StaticQuery
				query={graphql`
					{
						allCockpitWhereSection(filter: { lang: { eq: "en" } }) {
							edges {
								node {
									sections {
										value {
											value {
												section {
													value {
														childMarkdownRemark {
															htmlAst
															html
														}
													}
												}
											}
										}
									}
									firstTable {
										value {
											value {
												title {
													value {
														childMarkdownRemark {
															htmlAst
														}
													}
												}
												content {
													value {
														childMarkdownRemark {
															htmlAst
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				`}
				render={staticData => {
					const sections =
						staticData.allCockpitWhereSection.edges[0].node.sections
							.value;
					const firstTable =
						staticData.allCockpitWhereSection.edges[0].node
							.firstTable.value;
					return (
						<div className={styles.textSection} data-container>
							{sections.map((value, index) => {
								const markdown =
									value.value.section.value
										.childMarkdownRemark;
								return (
									<div
										key={index}
										className={classNames({
											[styles.blueSection]:
												index % 2 !== 0,
										})}
									>
										<div className={styles.section}>
											{new RehypeReact({
												createElement:
													React.createElement,
												components: {
													boosted: BoostedWord(
														styles.boosted
													),
												},
											}).Compiler(markdown.htmlAst)}
										</div>
										{index === 3 && (
											<WhereTable sections={firstTable} />
										)}
									</div>
								);
							})}
						</div>
					);
				}}
			/>
		);
	}
}

export default WhereSection;
