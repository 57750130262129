import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.css'

const BoostedWord = (injectedStyle = '') => ({ children }) => (
  <span className={classNames(styles.boosted, injectedStyle)}>{children}</span>
)

export default BoostedWord
