import { StaticQuery, graphql } from 'gatsby';
import BoostedWord from '../BoostedWord';
import classNames from 'classnames';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Quote from '../Quote';
import React, { Component } from 'react';
import RehypeReact from 'rehype-react';
// import ResourcesLink from '../ResearchSection/ResearchLink';
import ResearchLink from '../ResearchSection/ResearchLink';
import WhoLink from '../WhoSection/WhoLink';

import { scrollContainer } from '../Accordion';
import styles from './styles.module.css';

class WhatSection extends Component {
	constructor() {
		super();

		this.state = {
			expanded: false,
		};
	}

	handleChange = panel => (event, isExpanded) => {
		scrollContainer(event.currentTarget, panel);

		this.setState({
			expanded: isExpanded ? panel : false,
		});
	};

	render() {
		return (
			<StaticQuery
				query={graphql`
					query($locale: String) {
						allCockpitWhatSection(
							filter: { lang: { eq: $locale } }
						) {
							edges {
								node {
									sections {
										value {
											value {
												section {
													value {
														childMarkdownRemark {
															htmlAst
															html
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				`}
				render={staticData => {
					const sections =
						staticData.allCockpitWhatSection.edges[0].node.sections
							.value;

					return (
						<div className={styles.textSection} data-container>
							{sections.map((value, index) => {
								const markdown =
									value.value.section.value
										.childMarkdownRemark;
								return (
									<div
										key={index}
										className={classNames({
											[styles.yellowSection]:
												index % 2 !== 0,
										})}
									>
										<div className={styles.section}>
											{new RehypeReact({
												createElement:
													React.createElement,
												components: {
													boosted: BoostedWord(
														styles.boosted
													),
													wholink: WhoLink(
														this.openWho
													),
													researchlink: ResearchLink(
														this.openResearch
													),
												},
											}).Compiler(markdown.htmlAst)}
										</div>
									</div>
								);
							})}
						</div>
					);
				}}
			/>
		);
	}
}

export default WhatSection;
