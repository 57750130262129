import { isMobile } from '../../shared';
import { StaticQuery, graphql, navigate } from 'gatsby';
import BoostedWord from '../BoostedWord';
import eventEmitter from '../../eventEmitter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Quote from '../Quote';
import React from 'react';
import RehypeReact from 'rehype-react';
import ResourcesLink from '../ResearchSection/ResearchLink';
import Typography from '@material-ui/core/Typography';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	scrollContainer,
} from '../Accordion';
import styles from './styles.module.css';

export const query = graphql`
	query($locale: String) {
		allCockpitResearchSection(filter: { lang: { eq: $locale } }) {
			edges {
				node {
					sections {
						value {
							value {
								title {
									value {
										childMarkdownRemark {
											htmlAst
											html
										}
									}
								}
								section {
									value {
										childMarkdownRemark {
											htmlAst
											html
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default class ResearchSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
			ref: [
				React.createRef(),
				React.createRef(),
				React.createRef(),
				React.createRef(),
				React.createRef(),
				React.createRef(),
				React.createRef(),
				React.createRef(),
				React.createRef(),
				React.createRef(),
			],
		};
	}

	componentDidMount() {
		eventEmitter.on('displayResearchPicture', index => {
			if (index === false) {
				this.setState({
					expanded: false,
				});
			}
		});
	}

	handleChange = panel => (event, isExpanded) => {
		if (!isMobile()) {
			if (this.state.expanded === panel && !isMobile()) {
				eventEmitter.emit('displayResearchPicture', false);
			} else {
				eventEmitter.emit('displayResearchPicture', panel);
			}
		}
		// if (!this.state.expandedPanel && panel !== "0") {
		//     if(isMobile()) {
		//         this.scrollUp(panel-1)
		//     } else {
		//         this.scrollUp(panel)
		//     }
		// }

		scrollContainer(event.currentTarget, panel);

		this.setState({
			expanded: isExpanded ? panel : false,
		});
	};

	// componentWillUnmount = () => {
	// 	eventEmitter.off();
	// };

	// scrollUp = (panel) => {
	//     setTimeout(() => {
	//         this.state.ref[panel].current.scrollIntoView({ behavior: 'smooth' })
	//     }, 900)
	// }

	render() {
		return (
			<StaticQuery
				query={query}
				render={staticData => {
					const sections =
						staticData.allCockpitResearchSection.edges[0].node
							.sections.value;

					return (
						<>
							<div ref={this.state.ref[0]}></div>
							<div className={styles.textSection} data-container>
								{sections.map((value, index) => {
									const content =
										value.value.section.value
											.childMarkdownRemark;
									const title =
										value.value.title.value
											.childMarkdownRemark;

									return (
										<div key={index}>
											<ExpansionPanel
												expanded={
													this.state.expanded ===
													index
												}
												onChange={this.handleChange(
													index
												)}
											>
												<ExpansionPanelSummary
													expandIcon={
														<ExpandMoreIcon
															className={
																styles.icon
															}
														/>
													}
													aria-controls={`research-header-${index}`}
													id={`research-content-${index}`}
												>
													{title ? (
														<Typography
															component={'span'}
														>
															{new RehypeReact({
																createElement:
																	React.createElement,
																components: {
																	boosted: BoostedWord(
																		styles.boosted
																	),
																},
															}).Compiler(
																title.htmlAst
															)}
														</Typography>
													) : (
														<p>test</p>
													)}
												</ExpansionPanelSummary>
												<ExpansionPanelDetails>
													<Typography
														component={'span'}
													>
														<div
															className={
																styles.section
															}
														>
															{new RehypeReact({
																createElement:
																	React.createElement,
																components: {
																	boosted: BoostedWord(
																		styles.boosted
																	),
																	quote: Quote(
																		styles.quote
																	),
																	resourceslink: ResourcesLink(
																		this
																			.openWhere
																	),
																},
															}).Compiler(
																content.htmlAst
															)}
														</div>
													</Typography>
												</ExpansionPanelDetails>
											</ExpansionPanel>
										</div>
									);
								})}
							</div>
						</>
					);
				}}
			/>
		);
	}

	openWhere = () => {
		if (isMobile()) {
			navigate('/where');
		} else {
			eventEmitter.emit('openTray', 4);
		}
	};
}
