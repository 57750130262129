import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';

// Wrapper
export const ExpansionPanel = withStyles({
	root: {
		border: 'none',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiExpansionPanel);

// Title
export const ExpansionPanelSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		marginBottom: -1,
		minHeight: 75,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiExpansionPanelSummary);

// Content
export const ExpansionPanelDetails = withStyles(theme => ({
	root: {
		display: 'block',
		padding: theme.spacing(2),
	},
}))(MuiExpansionPanelDetails);

export function scrollContainer(accordion, panel) {
	const scrollEl = accordion.closest('[data-container]');

	// Get height of panel summary headers
	const height = accordion.getBoundingClientRect().height;

	// Calculate position
	const top = height * panel;

	// Scroll window to position;
	setTimeout(() => {
		scrollEl.scroll({
			top,
			behavior: 'smooth',
		});
	}, 1250);
}
