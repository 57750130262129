import classNames from 'classnames'
import React, { Component } from "react"

import styles from './styles.module.css'

class BurgerButton extends Component {
  render() {
    return (
      <button className={styles.button} onClick={this.onClick}>
        <div className={styles.icon}>
            <div className={classNames({
              [styles.burger]: true,
              [styles.burgerClicked]: !this.props.isToggled,
              })}>
            <div/>
          </div>
        </div>
      </button>
    )
  }

  onClick = () => {
    this.props.toggleTray()
  }
}

export default BurgerButton
