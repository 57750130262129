import classNames from 'classnames'
import React, { Component } from 'react'

import styles from './styles.module.css'

class Content extends Component {
  render() {
    return (
      <div
        className={classNames({
        [styles.content]: true,
        [styles.expanded]: this.props.expanded,
        })}
      >
        {this.props.content}
      </div>
    )
  }
}

export default Content
