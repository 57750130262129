import classNames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { navigate } from '@reach/router'
import React, { Component } from 'react'
import eventEmitter from '../../eventEmitter'
import WhatSection from '../WhatSection'
import ResearchSection from '../ResearchSection'
import WhereSection from '../WhereSection'

import Content from './Content'
import TrayItem from './TrayItem'
import MobileTrayItem from './MobileTrayItem'

import styles from './styles.module.css'
import WhoSection from '../WhoSection'
import HowSection from '../HowSection'

class Tray extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: undefined,
      hovered: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      hashedPage: this.props.location.hash,
    }
  }

  componentDidMount() {
    eventEmitter.on('openTray', id => {
      this.setState({ opened: undefined })
    })

    eventEmitter.on('closeTray', id => {
      this.setState({ opened: undefined })
    })

    eventEmitter.on('openItem', id => {
      this.setState({ opened: id })
    })
  }

  componentWillUnmount = () => {
    eventEmitter.off()
  }

  componentDidUpdate(prevState) {
    if (prevState.location.hash !== this.props.location.hash) {
      let location = prevState.location.hash
      window.addEventListener('hashchange', function() {
        if (location.indexOf('#') !== -1) {
          location = location.substring(1)
          navigate(location)
        }
      })
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allCockpitResearchSection(filter: { lang: { eq: "en" } }) {
              edges {
                node {
                  sections {
                    value {
                      value {
                        title {
                          value {
                            childMarkdownRemark {
                              htmlAst
                              html
                            }
                          }
                        }
                        section {
                          value {
                            childMarkdownRemark {
                              htmlAst
                              html
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            allCockpitResearchSection(filter: { lang: { eq: "en" } }) {
              edges {
                node {
                  pictures {
                    value {
                      value {
                        picture {
                          value {
                            id
                            childImageSharp {
                              id
                              fluid {
                                ...GatsbyImageSharpFluid_noBase64
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            cockpitMenu(cockpitId: { eq: "5d389f9574d1a20061208362" }) {
              menuItems {
                value {
                  title {
                    value
                  }
                  subtitle {
                    value
                  }
                  body {
                    value {
                      childMarkdownRemark {
                        html
                      }
                    }
                  }
                  image {
                    value {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                      publicURL
                    }
                  }
                }
              }
            }
          }
        `}
        render={staticData => {
          const {
            cockpitMenu: { menuItems },
          } = staticData
          const {
            allCockpitResearchSection: { edges },
          } = staticData
          const isExpanded = number => {
            return this.state.opened === number
          }

          const isSmall = number => {
            return this.state.opened ? this.state.opened !== number : false
          }

          const isOffscreen = number => {
            return (
              this.state.opened &&
              !(
                this.state.opened - 1 === number ||
                this.state.opened + 1 === number ||
                this.state.opened === number
              )
            )
          }

          const mobileTray = (
            <div
              className={classNames({
                [styles.mobileTray]: true,
              })}
            >
              <MobileTrayItem
                id={1}
                offscreen={isOffscreen(1)}
                color={'orange'}
                small={isSmall(1)}
                title={menuItems.value[0].title.value}
                subtitle={menuItems.value[0].subtitle.value}
                linkedPage={'/research'}
              />
              <MobileTrayItem
                id={2}
                offscreen={isOffscreen(2)}
                color={'yellow'}
                small={isSmall(2)}
                title={menuItems.value[1].title.value}
                subtitle={menuItems.value[1].subtitle.value}
                linkedPage={'/what'}
              />
              <MobileTrayItem
                id={3}
                offscreen={isOffscreen(3)}
                color={'green'}
                small={isSmall(3)}
                title={menuItems.value[2].title.value}
                subtitle={menuItems.value[2].subtitle.value}
                linkedPage={'/who'}
              />
              <MobileTrayItem
                id={4}
                offscreen={isOffscreen(4)}
                color={'blue'}
                small={isSmall(4)}
                title={menuItems.value[3].title.value}
                subtitle={menuItems.value[3].subtitle.value}
                linkedPage={'/where'}
              />
              <MobileTrayItem
                id={5}
                offscreen={isOffscreen(5)}
                color={'purple'}
                small={isSmall(5)}
                title={menuItems.value[4].title.value}
                subtitle={menuItems.value[4].subtitle.value}
                linkedPage={'/question'}
              />
            </div>
          )

          const webTray = (
            <div
              className={classNames({
                [styles.tray]: true,
                [styles.hiddenTray]: !this.props.visible,
              })}
            >
              <div
                className={classNames({
                  [styles.items]: true,
                  [styles.hiddenItems]: !this.props.visible,
                })}
                onMouseLeave={this.handleExit}
              >
                <TrayItem
                  id={1}
                  hovered={this.state.hovered}
                  handleHover={this.handleHover}
                  offscreen={isOffscreen(1)}
                  color={'orange'}
                  expanded={isExpanded(1)}
                  small={isSmall(1)}
                  handleTrays={this.handleTrays}
                  title={menuItems.value[0].title.value}
                  subtitle={menuItems.value[0].subtitle.value}
                  dynamicSubsectionTitles={edges[0].node.sections.value}
                  body={menuItems.value[0].body.value}
                  image={menuItems.value[0].image.value}
                  loading={this.props.loading}
                  pictures={edges[0].node.pictures.value}
                  linkedPage={'#research'}
                />
                <TrayItem
                  id={2}
                  hovered={this.state.hovered}
                  handleHover={this.handleHover}
                  offscreen={isOffscreen(2)}
                  color={'yellow'}
                  expanded={isExpanded(2)}
                  small={isSmall(2)}
                  handleTrays={this.handleTrays}
                  title={menuItems.value[1].title.value}
                  subtitle={menuItems.value[1].subtitle.value}
                  body={menuItems.value[1].body.value}
                  image={menuItems.value[1].image.value}
                  loading={this.props.loading}
                  pictures={edges[0].node.pictures.value}
                  linkedPage={'#what'}
                />
                <TrayItem
                  id={3}
                  hovered={this.state.hovered}
                  handleHover={this.handleHover}
                  offscreen={isOffscreen(3)}
                  color={'green'}
                  expanded={isExpanded(3)}
                  small={isSmall(3)}
                  handleTrays={this.handleTrays}
                  title={menuItems.value[2].title.value}
                  subtitle={menuItems.value[2].subtitle.value}
                  body={menuItems.value[2].body.value}
                  image={menuItems.value[2].image.value}
                  loading={this.props.loading}
                  pictures={edges[0].node.pictures.value}
                  linkedPage={'#who'}
                />
                <TrayItem
                  id={4}
                  hovered={this.state.hovered}
                  handleHover={this.handleHover}
                  offscreen={isOffscreen(4)}
                  color={'blue'}
                  expanded={isExpanded(4)}
                  small={isSmall(4)}
                  handleTrays={this.handleTrays}
                  title={menuItems.value[3].title.value}
                  subtitle={menuItems.value[3].subtitle.value}
                  body={menuItems.value[3].body.value}
                  image={menuItems.value[3].image.value}
                  loading={this.props.loading}
                  pictures={edges[0].node.pictures.value}
                  linkedPage={'#where'}
                />
                <TrayItem
                  id={5}
                  hovered={this.state.hovered}
                  handleHover={this.handleHover}
                  offscreen={isOffscreen(5)}
                  color={'purple'}
                  expanded={isExpanded(5)}
                  small={isSmall(5)}
                  handleTrays={this.handleTrays}
                  title={menuItems.value[4].title.value}
                  subtitle={menuItems.value[4].subtitle.value}
                  body={menuItems.value[4].body.value}
                  image={menuItems.value[4].image.value}
                  loading={this.props.loading}
                  pictures={edges[0].node.pictures.value}
                  linkedPage={'#question'}
                />
              </div>
              <div
                className={classNames({
                  [styles.pages]: true,
                  [styles.hiddenPages]: !this.props.visible,
                })}
              >
                {this.props.visible && (
                  <div
                    onClick={() => this.props.toggleTray()}
                    className={styles.closeTray}
                  />
                )}
                <Content
                  id={1}
                  expanded={isExpanded(1)}
                  content={<ResearchSection />}
                />
                <Content
                  id={2}
                  expanded={isExpanded(2)}
                  content={<WhatSection />}
                />
                <Content
                  id={3}
                  expanded={isExpanded(3)}
                  content={<WhoSection />}
                />
                <Content
                  id={4}
                  expanded={isExpanded(4)}
                  content={<WhereSection />}
                />
                <Content
                  id={5}
                  expanded={isExpanded(5)}
                  content={<HowSection />}
                />
              </div>
            </div>
          )
          return this.props.isMobile ? mobileTray : webTray
        }}
      />
    )
  }

  handleTrays = (trayId, hashedPage) => {
    if (!this.state.isMobile) {
      eventEmitter.emit('displayResearchPicture', false)
      navigate(hashedPage)
    }

    this.setState({
      opened: this.state.opened === trayId ? undefined : trayId,
    })
  }

  handleExit = () => {
    const hoverStatus = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    }
    this.setState({ hovered: hoverStatus })
  }

  handleHover = trayId => {
    const hoverStatus = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    }
    hoverStatus[trayId] = true
    const upOne = trayId - 1
    hoverStatus[upOne] = 'upOne'
    const upTwo = trayId - 2
    hoverStatus[upTwo] = 'upTwo'
    this.setState({ hovered: hoverStatus })
  }
}

export default Tray
