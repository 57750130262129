import { StaticQuery, graphql } from 'gatsby';
import BoostedWord from '../BoostedWord';
import classNames from 'classnames';
import React, { Component } from 'react';
import RehypeReact from 'rehype-react';

import { scrollContainer } from '../Accordion';
import styles from './styles.module.css';

class HowSection extends Component {
	constructor() {
		super();

		this.state = {
			expanded: false,
		};
	}

	handleChange = panel => (event, isExpanded) => {
		scrollContainer(event.currentTarget, panel);

		this.setState({
			expanded: isExpanded ? panel : false,
		});
	};

	render() {
		return (
			<StaticQuery
				query={graphql`
					{
						allCockpitQuestionsSection {
							edges {
								node {
									title {
										value
									}
									sections {
										value {
											value {
												section {
													value {
														childMarkdownRemark {
															htmlAst
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				`}
				render={staticData => {
					const sections =
						staticData.allCockpitQuestionsSection.edges[0].node
							.sections.value;

					return (
						<div className={styles.textSection} data-container>
							<div className={styles.title}>
								{
									staticData.allCockpitQuestionsSection
										.edges[0].node.title.value
								}
							</div>
							{sections.map((value, index) => {
								const markdown =
									value.value.section.value
										.childMarkdownRemark;
								return (
									<div
										key={index}
										className={classNames({
											[styles.blueSection]:
												index % 2 !== 0,
										})}
									>
										<div className={styles.section}>
											{new RehypeReact({
												createElement:
													React.createElement,
												components: {
													boosted: BoostedWord(
														styles.boosted
													),
												},
											}).Compiler(markdown.htmlAst)}
										</div>
									</div>
								);
							})}
						</div>
					);
				}}
			/>
		);
	}
}

export default HowSection;
