import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'

import styles from './styles.module.css'

const LanguageSelection = ({ pageContext }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            locales {
              langKeys
            }
          }
        }
      }
    `}
    render={staticData => (
      <span>
        {staticData.site.siteMetadata.locales.langKeys.map((langKey, index) => {
          const link =
            langKey === pageContext.locale ? (
              <span className={styles.selected}>{langKey.toUpperCase()}</span>
            ) : (
              <Link
                to={`/${langKey}${pageContext.slug}`}
                className={styles.unselected}
              >
                {langKey.toUpperCase()}
              </Link>
            )

          if (index > 0) {
            return (
              <span key={langKey}>
                <span className={styles.separator} />
                {link}
              </span>
            )
          }
          return <span key={langKey}>{link}</span>
        })}
      </span>
    )}
  />
)

export default LanguageSelection
