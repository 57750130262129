import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from "react";
import RehypeReact from 'rehype-react'

import styles from './styles.module.css'

const ExpansionPanel = withStyles({
    root: {
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: '#a5c7fa',
        marginBottom: -1,
        minHeight: 50,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: '#d9eeff',
    },
}))(MuiExpansionPanelDetails);

class WhereTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: undefined,
        }
    }

    handleChange = panel => (event, newExpanded) => {        
        const expandedPanel = (newExpanded ? panel : false)
        this.setState({ expanded: expandedPanel })
    };

    render() {
        const sections = this.props.sections
        return (
            <div
                className={styles.tableSection}
                key="research section"
            >
                {sections.map((value, index) => {
                    const title = value.value.title.value.childMarkdownRemark
                    const content = value.value.content.value.childMarkdownRemark
                    const panelId = String(index)
                    return <div key={index}>
                        <ExpansionPanel expanded={this.state.expanded === panelId} onChange={this.handleChange(panelId)}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon className={styles.icon} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography component={'span'}>
                                    {new RehypeReact({
                                        createElement: React.createElement,
                                    }).Compiler(title.htmlAst)
                                    }
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography component={'span'}>
                                    <div key={index} className={styles.section}>
                                        {new RehypeReact({
                                            createElement: React.createElement,
                                        }).Compiler(content.htmlAst)
                                        }
                                    </div>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                })}
            </div>
        )
    }
}

export default WhereTable