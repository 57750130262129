import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.css'

const Quote = (injectedStyle = '') => ({ children }) => (
  <span className={classNames(styles.quote, injectedStyle)}>{children}</span>
)

export default Quote
