import classNames from 'classnames'
import React, { Component } from 'react'

import styles from './styles.module.css'

class CollapseArrow extends Component {
  render() {
    return (
      <button className={styles.button}>
        <div className={styles.icon}>
          <div className={classNames({
            [styles.animated]: true,
            [styles.up]: this.props.isCollapsed,
            [styles.down]: !this.props.isCollapsed,
          })}>
          </div>
        </div>
      </button>
    )
  }
}

export default CollapseArrow
