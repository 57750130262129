import React, { Component } from 'react'

import styles from './styles.module.css'

class Triangle extends Component {
  render() {
    return(
      <div className={styles.triangle}/>
    )
  }
}

export default Triangle
