import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { IntlProvider, addLocaleData } from 'react-intl'
import enData from 'react-intl/locale-data/en'
import frData from 'react-intl/locale-data/fr'

import en from '../../messages/en.json'
import fr from '../../messages/fr.json'
import Navbar from '../Navbar'

const messages = { en, fr }

addLocaleData([...enData, ...frData])

const Layout = ({ children, pageContext, loading, location }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            baseUrl
            urlFr
            urlEn
            descriptionFr
            descriptionEn
            keywordsFr
            keywordsEn
          }
        }
        file(relativePath: { eq: "images/ReferencementImage.png" }) {
          publicURL
        }
      }
    `}
    render={staticData => {
      const { locale } = pageContext;

      const meta = [
        {
          property: 'og:title',
          content: staticData.site.siteMetadata.title,
        },
        {
          property: 'og:image',
          content:
            staticData.site.siteMetadata.baseUrl + staticData.file.publicURL,
        },
        {
          property: 'og:image:url',
          content:
            staticData.site.siteMetadata.baseUrl + staticData.file.publicURL,
        },
        {
          property: 'og:image:type',
          content: 'image/png',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image:width',
          content: 1080,
        },
        {
          property: 'og:image:height',
          content: 1080,
        },
      ]

      if (locale === 'fr') {
        meta.push(
          {
            property: 'og:url',
            content: staticData.site.siteMetadata.urlFr,
          },
          {
            property: 'og:description',
            content: staticData.site.siteMetadata.descriptionFr,
          },
          {
            name: 'description',
            content: staticData.site.siteMetadata.descriptionFr,
          },
          {
            name: 'keywords',
            content: staticData.site.siteMetadata.keywordsFr,
          }
        )
      } else {
        meta.push(
          {
            property: 'og:url',
            content: staticData.site.siteMetadata.urlEn,
          },
          {
            property: 'og:description',
            content: staticData.site.siteMetadata.descriptionEn,
          },
          {
            name: 'description',
            content: staticData.site.siteMetadata.descriptionEn,
          },
          {
            name: 'keywords',
            content: staticData.site.siteMetadata.keywordsEn,
          }
        )
      }

      if (!locale) {
        return (
          <div>
            <Helmet title={staticData.site.siteMetadata.title} meta={meta}>
              <html />
            </Helmet>
          </div>
        )
      }

      return (
        <IntlProvider locale={locale} messages={messages[locale]}>
          <div>
            <Helmet title={staticData.site.siteMetadata.title} meta={meta}>
              <html lang={locale} />
            </Helmet>
            <Navbar pageContext={pageContext} loading={loading} location={location}/>
            <div>{children}</div>
          </div>
        </IntlProvider>
      )
    }}
  />
)

export default Layout
