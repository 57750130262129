import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.css'

const ResearchLink = (handleClick) => ({ children }) => (
  <span onClick={handleClick} className={classNames(styles.link)}>{children}</span>
)

export default ResearchLink
